var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('main', [_c('b-row', {
    staticClass: "w-100"
  }, _vm._l(_vm.materis, function (materi, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "sm": "12",
        "md": "6",
        "lg": "4"
      }
    }, [_c('section', {
      staticClass: "cursor-pointer",
      on: {
        "click": function click($event) {
          return _vm.viewDetail(materi);
        }
      }
    }, [_c('b-card', [_c('main', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "mr-1"
    }, [_c('img', {
      staticStyle: {
        "width": "100px"
      },
      attrs: {
        "src": "/cover.jpg",
        "alt": ""
      }
    })]), _c('div', [_c('h4', {
      staticStyle: {
        "margin-bottom": "5px"
      }
    }, [_c('strong', [_vm._v(_vm._s(materi.nama_materi))])]), _c('span', {
      staticClass: "text-secondary"
    }, [_vm._v(_vm._s(materi.deskripsi))])])])])], 1)]);
  }), 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }