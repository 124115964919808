<template>
  <b-overlay :show="loading">
    <main>
      <b-row class="w-100">
        <b-col v-for="(materi, i) in materis" :key="i" sm="12" md="6" lg="4">
          <section class="cursor-pointer" @click="viewDetail(materi)">
            <b-card>
              <main class="d-flex align-items-center">
                <div class="mr-1">
                  <img src="/cover.jpg" style="width: 100px" alt="" />
                </div>
                <div>
                  <h4 style="margin-bottom: 5px">
                    <strong>{{ materi.nama_materi }}</strong>
                  </h4>
                  <span class="text-secondary">{{ materi.deskripsi }}</span>
                </div>
              </main>
            </b-card>
          </section>
        </b-col>
      </b-row>
    </main>
  </b-overlay>
</template>
<script>
import {
  BOverlay,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BTable,
} from "bootstrap-vue";
export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard,
    BTable,
  },
  data: () => ({
    form: {
      nama_materi: null,
      deskripsi: null,
      file: null,
      uploaded_file: null,
    },
    id: null,
    materis: [],
    loading: false,
    perPage: 10,
    pageOptions: [10, 30, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    search: null,
    fields: [
      { key: "index", label: "No" },
      { key: "nama_materi", label: "Judul Materi", sortable: true },
      { key: "deskripsi", label: "Deskripsi", sortable: true },
      { key: "action", label: "#" },
    ],
    selected: [],
    total: [],
    record: [],
    optFilter: [
      { id: "name", value: "Nama" },
      // { id: "nip", value: "NIP" },
    ],
  }),
  computed: {
    isValidForm() {
      if (!this.form.nama_materi || !this.form.deskripsi) {
        return false;
      }

      return true;
    },
  },
  methods: {
    viewDetail(item) {
      this.$router.push("/materi/detail/" + item.id);
    },
    getFileName(path) {
      const array = path.split("/");
      return array[array.length - 1];
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMateri() {
      const params = {};
      try {
        this.loading = true;
        const response = await this.$store.dispatch("materi/getData", params);
        this.materis = response?.data ? response.data : [];
        this.loading = false;
      } catch (e) {
        this.loading = false;
      }
    },
  },
  created() {
    this.getMateri();
  },
};
</script>
